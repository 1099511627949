import React, { useContext } from "react"
import { PopupNewsletterData } from "./hook"
// import "./style.scss"
import Img from "gatsby-image"
import NewsletterForm from "./form"
import { AppContext } from "../../context/AppContext"

const PopupNewsletter = ({ lang, toogleShowPopupNewsletter }) => {
  const data = PopupNewsletterData(lang)
  const { toogleNewsletterPopup } = useContext(AppContext)

  //   console.log(data)
  const {
    buttonText,
    consentText,
    contactForm7Id,
    descriptionBeforeFrom,
    placeholderBirthday,
    placeholderEmail,
    textErrorEmail,
    textSending,
    textSuccess,
    textUnderBirthday,
    background,
    textErrorBirthdayFormat,
    textErrorConsent,
  } = data
  return (
    <aside className="fs_popup fs_popup--newsletter">
      <div
        className="fs_popup__overlay"
        onClick={() => {
          toogleShowPopupNewsletter(false)
          toogleNewsletterPopup(false)
        }}
      ></div>
      <div className="fs_popup__background">
        <Img fluid={background.imageFile.childImageSharp.fluid} />
        <div className="fs_popup__wrapper">
          <div
            className="fs_popup__exit"
            onClick={() => {
              toogleShowPopupNewsletter(false)
              toogleNewsletterPopup(false)
            }}
          >
            X
          </div>
          <div
            className="fs_popup__description"
            dangerouslySetInnerHTML={{ __html: descriptionBeforeFrom }}
          />
          <div className="fs_popup__form">
            <NewsletterForm
              inputPlaceholder={placeholderEmail}
              successText={textSuccess}
              sendingText={textSending}
              errorEmail={textErrorEmail}
              formId={contactForm7Id}
              placeholderBirthday={placeholderBirthday}
              consentText={consentText}
              buttonText={buttonText}
              textUnderBirthday={textUnderBirthday}
              errorBirthday={textErrorBirthdayFormat}
              errorConsent={textErrorConsent}
            />
          </div>
        </div>
      </div>
    </aside>
  )
}
export default PopupNewsletter

export const WPaddToCart = products => {
  if (typeof window !== "undefined") {
    if (typeof window.wph !== "undefined") {
      window.wph("track", "AddToCart", {
        contents: products,
      })
    }
  }
}

export const WPOrder = ({
  transaction_id,
  value_gross,
  shipping_cost,
  discount_code,
  products,
}) => {
  if (typeof window !== "undefined") {
    if (typeof window.wph !== "undefined") {
      window.wph("track", "Purchase", {
        transaction_id: transaction_id,
        value_gross: value_gross,
        shipping_cost: shipping_cost,
        discount_code: discount_code,
        contents: products,
      })
    }
  }
}

export const WPViewContent = name => {
  if (typeof window !== "undefined") {
    if (typeof window.wph !== "undefined") {
      window.wph("track", "ViewContent", {
        content_name: name,
      })
    }
  }
}

export const WPViewContentProducts = (name, products) => {
  if (typeof window !== "undefined") {
    if (typeof window.wph !== "undefined") {
      window.wph("track", "ViewContent", {
        content_name: name,
        contents: products,
      })
    }
  }
}

/*
[
      {
        id: id,
        name: name,
        price: price,
        quantity: quantity,
      },
    ]
*/

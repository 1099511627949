/**
 * External dependencies.
 */
import PropTypes from 'prop-types';
import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import Footer from "./footer"

const FooterDE = ({ page }) => {
  return (
    <StaticQuery
      query={graphql`
				query FooterQueryDE {
          wpgraphql {
            themesOptionsPageDe {
              template {
                header{
                  homepage
                  logo {
                    sourceUrl
                    databaseId
                    modified
                    imageFile {
                      childImageSharp {
                        fluid(maxWidth: 125) {
                          base64
                          aspectRatio
                          src
                          srcSet
                          sizes
                          srcWebp
                          srcSetWebp
                        }
                      }
                    }
                  }
                }
                footer {
                  copy
                  textCounselor
                  counselorImg {
                    sourceUrl
                    databaseId
                    modified
                    imageFile {
                      childImageSharp {
                        fluid(maxWidth: 180) {
                          base64
                          aspectRatio
                          src
                          srcSet
                          sizes
                          srcWebp
                          srcSetWebp
                        }
                      }
                    }
                  }
                  counselorDescription
                  textProducts
                  navProducts {
                    link {
                      target
                      title
                      url
                    }
                  }
                  textFullspectrum
                  navFullspectrum {
                    link {
                      target
                      title
                      url
                    }
                  }
                  textClients
                  navClients {
                    link {
                      target
                      title
                      url
                    }
                  }
                  footerbar {
                    text
                    button {
                      target
                      title
                      url
                    }
                  }
                }
                socialmedia {
                  type
                  link {
                    target
                    title
                    url
                  }
                }
                cookies{
                  heading
                  description
                  buttonRejected
                  buttonAccept
                }
              }
            }
          }
				}` }
      render={data => <Footer page={page} dataTemplate={data.wpgraphql.themesOptionsPageDe} />}
    />
  )
}

FooterDE.propTypes = {
  lang: PropTypes.string,
};

FooterDE.defaultProps = {
  lang: 'pl',
  data: {
    wpgraphql: {}
  },
};

export default FooterDE
import { Link } from "gatsby"
import React from "react"
import { removeHost } from "../../utils/functions"
import "./style.scss"

const Button = ({
  title,
  url,
  target,
  action = false,
  deactive = false,
  className = false,
  passState = false,
}) => {
  const href = removeHost(url)
  return deactive ? (
    action ? (
      <a
        onClick={e => {
          e.preventDefault()
          action()
        }}
        className="btn btn--grey"
      >
        {title}
      </a>
    ) : (
      <Link
        onClick={e => {
          e.preventDefault()
        }}
        className="btn btn--deactive"
      >
        {title}
      </Link>
    )
  ) : action ? (
    <a
      onClick={e => {
        e.preventDefault()
        action()
      }}
      className="btn"
    >
      {title}
    </a>
  ) : passState ? (
    <Link
      className={`btn ${className ? className : ""}`}
      to={href}
      traget={target ? target : "_self"}
      state={passState}
    >
      {title}
    </Link>
  ) : (
    <Link
      className={`btn ${className ? className : ""}`}
      to={href}
      traget={target ? target : "_self"}
    >
      {title}
    </Link>
  )
}
export default Button

import React from 'react';
import "./style.scss"



const Spin = () => {
  return (
    <div className="spin">
      <div className="lds-dual-ring"></div>
    </div>
  )
}
export default Spin

import { useStaticQuery, graphql } from "gatsby"
export const PopupNewsletterData = (lang) => {
  const data = useStaticQuery(
    graphql`
      query PopupNewsletterData {
        wpgraphql {
          themesOptionsPage {
            template {
              popupNewsletter {
                buttonText
                consentText
                contactForm7Id
                descriptionBeforeFrom
                placeholderBirthday
                placeholderEmail
                textErrorEmail
                textSending
                textSuccess
                textUnderBirthday
                textErrorBirthdayFormat
                textErrorConsent
                background{
                  sourceUrl
                    databaseId
                    modified
                    imageFile {
                      childImageSharp {
                        fluid(maxWidth: 1200) {
                          base64
                          aspectRatio
                          src
                          srcSet
                          sizes
                          srcWebp
                          srcSetWebp
                        }
                      }
                    }
                }
              }
            }
          }
        }
      }
    `
  )
  switch (lang) {
    case 'pl':
      return data.wpgraphql.themesOptionsPage.template.popupNewsletter
    default:
      return data.wpgraphql.themesOptionsPage.template.popupNewsletter
  }
}

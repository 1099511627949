import React, { useState, useContext } from "react";
import { useLazyQuery, useMutation } from "@apollo/client";
import { AppContext } from "../context/AppContext";
import { getFormattedCart } from "../../utils/functions";
import { v4 } from "uuid";
import GET_CART from "../../queries/get-cart";
import REMOVE_FROM_CART from "../../mutations/remove-from-cart";
import "./style.scss"
import Spin from "../spin/Spin";
import FlyCartRemoveIcon from '../../images/fly_cart_remove.svg'

const ButtonRemoveFromCart = ({ title, cartKey, icon = false }) => {



  /* eslint-disable */
  const { setCart, lang, shippingID } = useContext(AppContext);
  const [removeingFromCart, setRemoveingFromCart] = useState(false);
  const [requestError, setRequestError] = useState(null);

  const productInput = {
    clientMutationId: v4(), // Generate a unique id.
    keys: cartKey,
    wpmlLanguage: lang
  };

  // Get Cart Data.
  const [getCart, { loading, error, data }] = useLazyQuery(GET_CART, {
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'network-only',
    variables: {
      country: lang.toUpperCase(),
      shippingID: shippingID,
      wpmlLanguage: lang
    },
    onCompleted: () => {
      // console.warn( 'completed GET_CART' );

      // Update cart in the localStorage.
      const updatedCart = getFormattedCart(data);
      localStorage.setItem("woo-next-cart", JSON.stringify(updatedCart));
      // setAddingToCart(false);
      // toogleShowFlyCart(true);
      // Update cart data in React Context.
      setCart(updatedCart);
    }
  });
  // Add to Cart Mutation.
  const [
    removeFromCart,
    { data: removeFromCartRes, loading: removeFromCartLoading, error: removeFromCartError },
  ] = useMutation(REMOVE_FROM_CART, {
    variables: {
      input: productInput,

    },
    onCompleted: () => {
      // If error.
      // if (removeFromCartError) {
      //   if (removeFromCartError.graphQLErrors) {
      //     setRequestError(removeFromCartError.graphQLErrors[0].message);
      //   }
      // }

      // On Success:
      // 1. Make the GET_CART query to update the cart with new values in React context.
      getCart();

      // 2. Show View Cart Button
      // setShowViewCart(true);
    },
    onError: (errors) => {
      if (errors) {
        setRequestError(error?.graphQLErrors?.[0]?.message ?? '');
      }
    },
  });

  const handleRemoveFromCartClick = () => {
    setRequestError(null);
    setRemoveingFromCart(true);
    removeFromCart();
  };

  return (
    <div className="removeFromCart">
      {icon ? (
        <a onClick={(e) => { e.preventDefault(); handleRemoveFromCartClick(); }} >
          <FlyCartRemoveIcon />
          <span>
            {title}
          </span>
        </a>
      ) : (
        <a onClick={(e) => { e.preventDefault(); handleRemoveFromCartClick(); }} className="btn" >
          {title}
        </a>
      )}
      {removeingFromCart && <Spin />}
    </div>
  )
}
export default ButtonRemoveFromCart

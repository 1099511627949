import React, { useContext } from "react"
import { AppContext } from "../context/AppContext"
import PL from "../../images/pl.svg"
import DE from "../../images/de.svg"
import FR from "../../images/fr.svg"
import EN from "../../images/gb.svg"
import Arrow from "../../images/arrow_down.svg"

import { Link } from "gatsby"
import { isEmpty } from "lodash"
import { removeHost } from "../../utils/functions";


const Flags = () => {
  const { WPML, lang } = useContext(AppContext)
  return (
    <div className="lang">
      {lang === "pl" && <PL />}
      {lang === "de" && <DE />}
      {lang === "fr" && <FR />}
      {lang === "en" && <EN />}
      <Arrow />
      <ul>
        {!isEmpty(WPML) ? WPML.map((lang, index) => (
          <li key={`flag_${index}`}>
            <Link to={removeHost(lang.permalink)}>
              {lang.code === "pl" && <PL />}
              {lang.code === "de" && <DE />}
              {lang.code === "fr" && <FR />}
              {lang.code === "en" && <EN />}
            </Link>
          </li>
        )): ''}
      </ul>
    </div>
  )
}

export default Flags

import React from "react"
import Link from "gatsby-link"
import { useState } from "react"
import { getCookie, isMobile, removeHost } from "../../utils/functions"

import Img from "gatsby-image"
import { v4 as uuid } from "uuid"
import Search from "../../images/Search.svg"
import MyAccout from "../../images/MyAccout.svg"
import Arrow from "../../images/arrow_down.svg"
import Hamburger from "../../images/hamburger.svg"
import Exit from "../../images/exit.svg"

import CartIcon from "../cart/cart-icon"
import "./style.scss"
import CartFly from "../cart/cart-dropdown"
import { isEmpty } from "lodash"
import Flags from "./Flags"

const Header = ({ dataTemplate }) => {
  const [menuVisible, setMenuVisibility] = useState(false)
  const [subNavOpen, toogleSubNavOpen] = useState(false)
  const [searchOpen, tooglesearchOpen] = useState(false)
  const logo = dataTemplate.template.header.logo.imageFile.childImageSharp.fluid
  // const helpLine = dataTemplate.template.header.helpline
  // const topBanner = dataTemplate.template.header.topbanner
  // const nav = dataTemplate.template.header.nav;
  // const homeUrl = dataTemplate.template.header.homepage;
  const {
    helpline: helpLine,
    topbanner: topBanner,
    nav,
    homepage: homeUrl,
    searchpage,
    myaccountpage,
    searchPlaceholder,
    searchButton,
  } = dataTemplate.template.header

  const toggleMenu = () => {
    setMenuVisibility(!menuVisible)
  }

  const handleClickSubNav = e => {
    if (isMobile()) {
      if (!subNavOpen) {
        e.preventDefault()
      }
      toogleSubNavOpen(true)
    }
  }

  const handleSearch = e => {
    if (!isMobile()) {
      e.preventDefault()
      tooglesearchOpen(!searchOpen)
    }
  }

  return (
    <>
      {topBanner && <div className="header__topBanner--empty"></div>}
      <header className="header">
        {topBanner && (
          <div className="header__topBanner">
            <div className="container">
              <div className="header__topBanner__wrapper">
                <p>{topBanner}</p>
              </div>
            </div>
          </div>
        )}
        <div className="container">
          <div className="header__wrapper">
            <div className="header__logo">
              <Link to={homeUrl}>
                <Img fluid={logo} />
              </Link>
            </div>
            <nav className={`header__navPrimary ${menuVisible ? "open" : ""}`}>
              <ul>
                {nav.map(navitem => (
                  <li
                    key={uuid()}
                    className={`${
                      navitem.subnav ? "header__navPrimary__sub" : ""
                    }`}
                  >
                    <Link
                      key={uuid()}
                      to={removeHost(navitem.item.url)}
                      onClick={e => {
                        if (navitem.subnav) {
                          handleClickSubNav(e)
                        }
                      }}
                    >
                      {navitem.item.title}
                      {navitem.subnav ? <Arrow /> : ""}
                    </Link>
                    {navitem.subnav ? (
                      <>
                        <ul
                          className={`${subNavOpen ? "active" : ""}`}
                          key={uuid()}
                        >
                          {navitem.subnav.map(
                            subitem =>
                              !isEmpty(subitem.item) && (
                                <li key={uuid()}>
                                  <Link
                                    key={uuid()}
                                    to={removeHost(subitem.item.url)}
                                  >
                                    {subitem.item.title}
                                  </Link>
                                </li>
                              )
                          )}
                        </ul>
                      </>
                    ) : (
                      ""
                    )}
                  </li>
                ))}
                {helpLine && (
                  <li className="header__navPrimary__helpline">
                    <a href="#cp-widget">{helpLine.title}</a>
                  </li>
                )}
              </ul>
              <button
                className={`header__navPrimary__exit`}
                type="button"
                onClick={toggleMenu}
                onKeyDown={toggleMenu}
              >
                <Exit />
              </button>
            </nav>
            <nav className="header__navSecondary">
              <ul>
                <li
                  className={`header__navSecondary__search ${
                    searchOpen ? "active" : ""
                  }`}
                >
                  <Link
                    onClick={e => {
                      handleSearch(e)
                    }}
                    to={searchpage}
                  >
                    <Search />
                  </Link>

                  <div className="header__navSecondary__search__form">
                    <form action={searchpage}>
                      <div className="search__form__input form__input">
                        <input
                          id="search"
                          name="search"
                          type="text"
                          placeholder={searchPlaceholder}
                          // {...register("search", {})}
                        />
                      </div>
                      <div className="search__form__button form__button checkout__order__button">
                        <button type="submit">{searchButton}</button>
                      </div>
                    </form>
                  </div>
                </li>
                <li className="header__navSecondary__myAccout">
                  <Link to={myaccountpage}>
                    <MyAccout />
                  </Link>
                </li>
                <li className="header__navSecondary__basket">
                  <CartIcon />
                </li>
                <li className="header__navSecondary__flags">
                  <Flags />
                </li>
              </ul>
              <button
                className={`${menuVisible ? "active" : ""} header__hamburger`}
                type="button"
                onClick={toggleMenu}
                onKeyDown={toggleMenu}
              >
                <Hamburger />
              </button>
            </nav>
          </div>
        </div>
      </header>
      <CartFly />
    </>
  )
}

export default Header

import { useStaticQuery, graphql } from "gatsby"
export const FlyCartData = (lang) => {
  const data = useStaticQuery(
    graphql`
      query CartData {
        wpgraphql {
          themesOptionsPage {
            template {
              flyBasket {
                heading
                buttonContinue
                buttonGoToCart {
                  target
                  title
                  url
                }
                basketEmpty
                removeText
                textLikeIt
                textTotal
                textUnderAddIcon
                products {
                  product {
                    ... on WPGraphQL_SimpleProduct {
                      id
                      databaseId
                      uri
                      name
                      featuredImage {
                        node {
                          mediaItemUrl
                          altText
                          imageFile {
                            childImageSharp {
                              id
                            }
                          }
                        }
                      }
                      regularPrice
                      salePrice
                    }
                  }
                }
              }
            }
          }
          themesOptionsPageEn {
            template {
              flyBasket {
                heading
                buttonContinue
                buttonGoToCart {
                  target
                  title
                  url
                }
                removeText
                textLikeIt
                textTotal
                textUnderAddIcon
                products {
                  product {
                    ... on WPGraphQL_SimpleProduct {
                      id
                      databaseId
                      uri
                      name
                      featuredImage {
                        node {
                          mediaItemUrl
                          imageFile {
                            childImageSharp {
                              id
                            }
                          }
                        }
                      }
                      regularPrice
                      salePrice
                    }
                  }
                }
              }
            } 
          }
          themesOptionsPageFr {
            template {
              flyBasket {
                heading
                buttonContinue
                buttonGoToCart {
                  target
                  title
                  url
                }
                removeText
                textLikeIt
                textTotal
                textUnderAddIcon
                products {
                  product {
                    ... on WPGraphQL_SimpleProduct {
                      id
                      databaseId
                      uri
                      name
                      featuredImage {
                        node {
                          mediaItemUrl
                          imageFile {
                            childImageSharp {
                              id
                            }
                          }
                        }
                      }
                      regularPrice
                      salePrice
                    }
                  }
                }
              }
            }
          }
          themesOptionsPageDe {
            template {
              flyBasket {
                heading
                buttonContinue
                buttonGoToCart {
                  target
                  title
                  url
                }
                removeText
                textLikeIt
                textTotal
                textUnderAddIcon
                products {
                  product {
                    ... on WPGraphQL_SimpleProduct {
                      id
                      databaseId
                      uri
                      name
                      featuredImage {
                        node {
                          mediaItemUrl
                          imageFile {
                            childImageSharp {
                              id
                            }
                          }
                        }
                      }
                      regularPrice
                      salePrice
                    }
                  }
                }
              }
            }
          }
        }
      }
    `
  )
  switch (lang) {
    case 'pl':
      return data.wpgraphql.themesOptionsPage.template.flyBasket
    case 'en':
      return data.wpgraphql.themesOptionsPageEn.template.flyBasket
    case 'de':
      return data.wpgraphql.themesOptionsPageDe.template.flyBasket
    case 'fr':
      return data.wpgraphql.themesOptionsPageDe.template.flyBasket
    default:
      return data.wpgraphql.themesOptionsPage.template.flyBasket
  }
}

/**
 * External dependencies.
 */
import PropTypes from 'prop-types';
import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import Header from "./header"

const HeaderDE = ({ translated }) => {
  return (
    <StaticQuery
      query={graphql`
				    query HeaderQueryDE {
						wpgraphql {
							themesOptionsPageDe {
								template {
									header {
                    homepage
                    searchpage
                    myaccountpage
										topbanner
                    searchPlaceholder
                    searchButton
										logo {
                      sourceUrl
                      databaseId
                      modified
                      imageFile {
                        childImageSharp {
                          fluid(maxWidth: 150) {
                            base64
                            aspectRatio
                            src
                            srcSet
                            sizes
                            srcWebp
                            srcSetWebp
                          }
                        }
                      }
										}
                    nav {
                      item {
                        url
                        title
                        target
                      }
                      subnav {
                        item {
                          target
                          title
                          url
                        }
                      }
                    }
										helpline {
											target
											title
											url
										}
									}
								}
							}
						}
				  }` }
      render={data => <Header translated={translated} dataTemplate={data.wpgraphql.themesOptionsPageDe} />}
    />
  )
}

HeaderDE.propTypes = {
  lang: PropTypes.string,
};

HeaderDE.defaultProps = {
  lang: 'de',
  data: {
    wpgraphql: {}
  },
};

export default HeaderDE
import React, { useContext, useEffect } from "react"
import { AppContext } from "../../context/AppContext"
import { LazyLoadImage } from "react-lazy-load-image-component"
import { isEmpty } from "lodash"
import Link from "gatsby-link"
import { FlyCartData } from "./hook"
import ButtonRemoveFromCart from "../../buttonRevemoFromCart/ButtonRevemoFromCart"
import ButtonAddToCart from "../../buttonAddToCart/ButtonAddToCart"
import Button from "../../button/Button"
import { Pagination } from "swiper"
import { Swiper, SwiperSlide } from "swiper/react"

import "swiper/css"
// import 'swiper/css/navigation';
import "swiper/css/pagination"
import "./style.scss"
import { priceToFloat } from "../../../utils/functions"

const CartFly = () => {
  const {
    cart,
    showFlyCart,
    toogleShowFlyCart,
    lang,
    pageName,
    addToCartError,
    addToCartErrorIdProduct,
  } = useContext(AppContext)

  const data = FlyCartData(lang)
  const {
    buttonContinue,
    buttonGoToCart,
    products,
    removeText,
    textLikeIt,
    textTotal,
    textUnderAddIcon,
    heading,
    basketEmpty,
  } = data
  const totalPrice = cart?.totalProductsWithoutCoupnPrice
    ? cart.totalProductsWithoutCoupnPrice
    : ""
  const productImagePlaceholder = "https://via.placeholder.com/70"
  const hideFlyCart = () => {
    toogleShowFlyCart(false)
  }

  useEffect(() => {
    return toogleShowFlyCart(false)
  }, [])

  if (pageName === "cart") {
    return ""
  }
  if (pageName === "checkout") {
    return ""
  }

  return (
    <>
      <div
        className={`shopping-cart-overlay ${showFlyCart ? "is-open" : ""} `}
        onClick={e => {
          e.preventDefault()
          toogleShowFlyCart(false)
        }}
      ></div>
      <div className={`shopping-cart ${showFlyCart ? "is-open" : ""}`}>
        <div className="shopping-cart__wrapper">
          <div className="shopping-cart-header">
            <h2 className="heading--small">{heading}</h2>
            <button
              className="shopping-cart-header__hide"
              onClick={e => {
                e.preventDefault()
                toogleShowFlyCart(false)
              }}
            >
              <span></span>
              <span></span>
            </button>
          </div>
          {null === cart || !Object.keys(cart).length ? (
            <h2 className="heading heading--normal shopping-cart-header__heading">
              {basketEmpty}
            </h2>
          ) : (
            <>
              <ul className="shopping-cart-items">
                {cart?.products.map(product => (
                  <li className="clearfix" key={product.cartKey}>
                    <Link to={product.uri} className="item-image">
                      {!isEmpty(product.image) ? (
                        <figure>
                          <LazyLoadImage
                            alt={
                              product.image.altText ? product.image.altText : ""
                            }
                            src={product.image.sourceUrl} // use normal <img> attributes as props
                            effect="blur"
                            height="80"
                            width="80"
                          />
                        </figure>
                      ) : !isEmpty(productImagePlaceholder) ? (
                        <figure>
                          <LazyLoadImage
                            alt="default"
                            height="70"
                            src={productImagePlaceholder}
                            width="70"
                            effect="blur"
                          />
                        </figure>
                      ) : null}
                    </Link>
                    <span className="item-data">
                      <span className="item-name">{product.name}</span>
                      <span className="item-price">
                        {product.qty} X{" "}
                        <span
                          dangerouslySetInnerHTML={{
                            __html: product.priceSingleProduct,
                          }}
                        ></span>
                      </span>
                    </span>
                    <span className="item-remove">
                      <ButtonRemoveFromCart
                        title={removeText}
                        icon={true}
                        cartKey={product.cartKey}
                      />
                    </span>
                  </li>
                ))}
              </ul>
              <div className="shopping-cart-summary">
                {cart.totalProductsWithoutCoupnFloat ? (
                  <div className="shopping-cart-summary__total">
                    <p className="shopping-cart-summary__total__heading">
                      {textTotal}
                    </p>
                    <p
                      className="shopping-cart-summary__total__price"
                      dangerouslySetInnerHTML={{ __html: totalPrice }}
                    />
                  </div>
                ) : (
                  ""
                )}
                {cart.subscription ? (
                  <>
                    <div className="shopping-cart-summary__total">
                      <p className="shopping-cart-summary__total__heading">
                        {textTotal}
                      </p>
                      <p
                        className="shopping-cart-summary__total__price"
                        dangerouslySetInnerHTML={{
                          __html: cart.subscriptionData.totalPrice,
                        }}
                      />
                    </div>
                  </>
                ) : (
                  ""
                )}
                <div className="shopping-cart-summary__buttons">
                  <Button
                    title={buttonContinue}
                    url="#"
                    action={() => {
                      hideFlyCart()
                    }}
                    deactive={true}
                  />
                  <Button
                    title={buttonGoToCart.title}
                    url={buttonGoToCart.url}
                  />
                </div>
              </div>
            </>
          )}
          {products && (
            <div className="shopping-cart-footer">
              <p>{textLikeIt}</p>
              <div className="shopping-cart-footer__wrapper shopping-cart-items">
                <Swiper
                  // install Swiper modules
                  modules={[Pagination]}
                  slidesPerView={1}
                  pagination={{ clickable: true }}
                  observer
                  observeParents
                >
                  {products.map(({ product }) => {
                    let floatPrice = product.salePrice
                      ? priceToFloat(product.salePrice)
                      : priceToFloat(product.regularPrice)

                    const dataProductForPixel = {
                      id: product.databaseId,
                      name: product.name,
                      price: floatPrice,
                      quantity: 1,
                    }

                    return !isEmpty(product) ? (
                      <SwiperSlide
                        key={product.id}
                        className="shopping-cart-footer__item"
                      >
                        {}
                        <li className="clearfix">
                          <Link to={product.uri} className="item-image">
                            {!isEmpty(
                              product.featuredImage.node.mediaItemUrl
                            ) ? (
                              <figure>
                                <LazyLoadImage
                                  alt={
                                    product.featuredImage.node.altText
                                      ? product.featuredImage.node.altText
                                      : ""
                                  }
                                  src={product.featuredImage.node.mediaItemUrl} // use normal <img> attributes as props
                                  effect="blur"
                                  height="80"
                                  width="80"
                                />
                              </figure>
                            ) : !isEmpty(productImagePlaceholder) ? (
                              <figure>
                                <LazyLoadImage
                                  alt="default"
                                  height="80"
                                  src={productImagePlaceholder}
                                  width="80"
                                  effect="blur"
                                />
                              </figure>
                            ) : null}
                          </Link>
                          <span className="item-data">
                            <span className="item-name">{product.name}</span>
                            <span className="item-price">
                              {product.salePrice ? (
                                <>
                                  <span
                                    dangerouslySetInnerHTML={{
                                      __html: product.salePrice,
                                    }}
                                  ></span>
                                  <del
                                    dangerouslySetInnerHTML={{
                                      __html: product.regularPrice,
                                    }}
                                  ></del>
                                </>
                              ) : (
                                <span
                                  dangerouslySetInnerHTML={{
                                    __html: product.regularPrice,
                                  }}
                                ></span>
                              )}
                            </span>
                          </span>
                          <span className="item-remove">
                            <ButtonAddToCart
                              title={textUnderAddIcon}
                              quantity={1}
                              productID={product.databaseId}
                              icon={true}
                              dataProductForPixel={dataProductForPixel}
                            />
                          </span>
                        </li>

                        {addToCartError &&
                        +product.databaseId === addToCartErrorIdProduct ? (
                          <p className="productSingleHero__buy__cant">
                            {addToCartError}
                          </p>
                        ) : (
                          ""
                        )}
                      </SwiperSlide>
                    ) : (
                      ""
                    )
                  })}
                </Swiper>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  )
}

export default CartFly

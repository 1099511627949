import { useStaticQuery, graphql } from "gatsby"
export const PopupExitData = (lang) => {
  const data = useStaticQuery(
    graphql`
      query PopupExitData {
        wpgraphql {
          themesOptionsPage {
            template {
              popupExit {
                textBeforeCoupon
                textAfterCoupon
                couponCode
                background {
                  sourceUrl
                  databaseId
                  modified
                  imageFile {
                    childImageSharp {
                      fluid(maxWidth: 1200) {
                        base64
                        aspectRatio
                        src
                        srcSet
                        sizes
                        srcWebp
                        srcSetWebp
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    `
  )
  switch (lang) {
    case 'pl':
      return data.wpgraphql.themesOptionsPage.template.popupExit
    default:
      return data.wpgraphql.themesOptionsPage.template.popupExit
  }
}

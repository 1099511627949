import { gql } from "@apollo/client";

const GET_CART = gql`
query GET_CART($country: String, $shippingID: String, $wpmlLanguage: String) {
  cart(wpmlLanguage: $wpmlLanguage) {
    id
    contents {
      nodes {
        key
        subtotalWithoutCoupnPrice
        isSmartCoupon
        promotionText
        product {
          node {
            id
            productId: databaseId
            name
            description
            type
            onSale
            slug
            averageRating
            reviewCount
            ... on SimpleProduct {
              price
              uri
            }
            ... on SubscriptionProduct {
              price
              uri
            }
            image {
              id
              sourceUrl
              srcSet
              altText
              title
            }
            productCategories {
              nodes {
                name
              }
            }
            galleryImages {
              nodes {
                id
                sourceUrl
                srcSet
                altText
                title
              }
            }
          }
        }
        variation {
          node {
            id
            variationId: databaseId
            name
            description
            type
            onSale
            price
            regularPrice
            salePrice
            image {
              id
              sourceUrl
              srcSet
              altText
              title
            }
          }
          attributes {
            id
            name
            value
          }
        }
        quantity
        total
        subtotal
        subtotalTax
      }
    }
    appliedCoupons {
      code
      discountAmount
      discountTax
      couponName
      discountAmountType
      couponForProduct
      couponForCategory
    }
    totalPriceWithShippingAndCouponFloat(shippingID: $shippingID, country: $country)
    totalPriceWithShippingAndCoupon(shippingID: $shippingID, country: $country)
    totalPriceWithCoupon
    totalWithoutCoupnPrice
    totalWithoutCoupnFloat
    discountAllAmountPrice
    subtotal
    subtotalTax
    shippingTax
    shippingTotal
    total
    totalTax
    feeTax
    feeTotal
    discountTax
    discountTotal
    currenCurrency
    subscriptiontotalWithoutCoupnPrice
    subscriptionTotalWithoutCoupnFloat
    subscriptionTotalPriceWithShippingAndCoupon(shippingID: $shippingID, country: $country)
  }
}
`;

export default GET_CART;
/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import * as React from "react"
import PropTypes from "prop-types"

import HeaderPL from "./header/HeaderPL"
import FooterPL from "./footer/FooterPL"
import "./layout.css"
import "../fonts/fonts.css"
import "../sass/global.scss"

import { Helmet } from "react-helmet"
import { getCookie } from "../utils/functions"
import HeaderDE from "./header/HeaderDE"
import FooterDE from "./footer/FooterDE"
import { useEffect, useState, useContext } from "react"
import PopupNewsletter from "./popups/newslettrer/Newslettrer"
import { AppContext } from "./context/AppContext"
import PopupExit from "./popups/exit/Exit"

const Layout = ({ children, lang, page, translated }) => {
  const [popupNewsletter, toogleShowPopupNewsletter] = useState(false)
  const [popupExit, toogleShowPopupExit] = useState(true)
  const [useLeaved, setUseLeaved] = useState(false)

  const { newsletterPopup, exitPopup } = useContext(AppContext)

  const handleMouseLeave = event => {
    const { top } = event.target.getBoundingClientRect()
    const { clientY } = event
    if (!useLeaved && clientY < top) {
      setUseLeaved(true)
    }
  }

  useEffect(() => {
    if (typeof window !== "undefined") {
      if (window.google_tag_manager !== undefined) {
        var gtm = window.google_tag_manager[`${process.env.googleTagManager}`]
        gtm.dataLayer.reset()
      }

      window.dataLayer = window.dataLayer || []
      window.dataLayer.push({
        originalLocation:
          document.location.protocol +
          "//" +
          document.location.hostname +
          document.location.pathname +
          document.location.search,
      })
      setTimeout(() => {
        if (newsletterPopup) {
          toogleShowPopupNewsletter(true)
        }
      }, 10000)
    }
  }, [])

  return (
    <>
      <Helmet>
        <meta
          name="google-site-verification"
          content="v_dgmTCGzVwMwisP9h5QyZ_LRFJwSJmW4j3C6Djozpw"
        />
        <script>
          {/* {`
              const dataLayer = window.dataLayer || [];
              function gtag() { dataLayer.push(arguments); }
              gtag('js', new Date());
              gtag('config', '${process.env.googleAnalytics}');
          `} */}
        </script>

        {/* <script
          type="text/javascript"
          ata-rh="true"
          data-react-helmet="true"
          src={`https://t.goadservices.com/tags/${process.env.goPl}`}
        ></script> */}

        <script type="text/javascript" ata-rh="true" data-react-helmet="true">
          {`var __cp = {"id":"rYoLJd4_L5o1cmeJG5yxb4wKLClfsO_aBdG66MOSfW4","version":"1.1"};(function (window, document) {var cp = document.createElement('script');cp.type = 'text/javascript';cp.async = false;cp.src = "++cdn-widget.callpage.io+build+js+callpage.js".replace(/[+]/g, '/').replace(/[=]/g, '.');var s = document.getElementsByTagName('script')[0];s.parentNode.insertBefore(cp, s);if (window.callpage) {alert('You could have only 1 CallPage code on your website!');} else {window.callpage = function (method) { if (method == '__getQueue') { return this.methods; } else if (method) { if (typeof window.callpage.execute === 'function') { return window.callpage.execute.apply(this, arguments); } else { (this.methods = this.methods || []).push({ arguments: arguments }); } } };window.callpage.__cp = __cp;window.callpage('api.button.autoshow');}})(window, document);`}
        </script>

        <script>
          {`
            !function(d,m,e,v,n,t,s){d['WphTrackObject'] = n; d[n] = window[n] || function() {(d[n].queue=d[n].queue||[]).push(arguments)}, d[n].l = 1 * new Date(), t=m.createElement(e), s=m.getElementsByTagName(e)[0], t.async=1;t.src=v;s.parentNode.insertBefore(t,s)}(window,document,'script', 'https://pixel.wp.pl/w/tr.js', 'wph'); wph('init', 'WP-ADS-WP-ADS-PYCT7-5HL');
          `}
        </script>
        <script type="text/javascript" ata-rh="true" data-react-helmet="true">
          {`
   (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
   new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
   j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
   'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
   })(window,document,'script','dataLayer','${process.env.googleTagManager}');
          `}
        </script>

        <script type="text/javascript">
          {`
          (function(m, o, n, t, e, r, _){
          m['__GetResponseAnalyticsObject'] = e;m[e] = m[e] || function() {(m[e].q = m[e].q || []).push(arguments)};
          r = o.createElement(n);_ = o.getElementsByTagName(n)[0];r.async = 1;r.src = t;r.setAttribute('crossorigin', 'use-credentials');_.parentNode .insertBefore(r, _);
          })(window, document, 'script', 'https://ga.getresponse.com/script/d678e04c-a555-4e0e-9678-bff0425b9dfa/ga.js', 'GrTracking');
      `}
        </script>
      </Helmet>

      {lang === "pl" && (
        <div onMouseLeave={handleMouseLeave}>
          <HeaderPL lang={lang} translated={translated} />
          <main>{children}</main>
          <FooterPL page={page} />
          {popupNewsletter && (
            <PopupNewsletter
              toogleShowPopupNewsletter={toogleShowPopupNewsletter}
            />
          )}
          {popupExit && exitPopup && useLeaved && (
            <PopupExit toogleShowPopupExit={toogleShowPopupExit} />
          )}
        </div>
      )}

      {lang === "de" && (
        <>
          <HeaderDE lang={lang} translated={translated} />
          <main>{children}</main>
          <FooterDE page={page} />
        </>
      )}
    </>
  )
}

Layout.propTypes = {
  lang: PropTypes.string,
  page: PropTypes.string,
  children: PropTypes.node.isRequired,
}

export default Layout

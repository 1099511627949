import React, { useContext } from "react"
import { PopupExitData } from "./hook"
// import "./style.scss"
import Img from "gatsby-image"
import { v4 as uuid } from "uuid"
import { AppContext } from "../../context/AppContext"

const PopupExit = ({ lang, toogleShowPopupExit }) => {
  const data = PopupExitData(lang)
  const { textBeforeCoupon, textAfterCoupon, couponCode, background } = data
  const { toogleExitPopup } = useContext(AppContext)

  return (
    <aside className="fs_popup fs_popup--exit">
      <div
        className="fs_popup__overlay"
        onClick={() => {
          toogleShowPopupExit(false)
          toogleExitPopup(false)
        }}
      ></div>
      <div className="fs_popup__background">
        <Img fluid={background.imageFile.childImageSharp.fluid} />
        <div className="fs_popup__wrapper">
          <div
            className="fs_popup__exit"
            onClick={() => {
              toogleShowPopupExit(false)
              toogleExitPopup(false)
            }}
          >
            X
          </div>
          <div
            className="fs_popup__description"
            dangerouslySetInnerHTML={{ __html: textBeforeCoupon }}
          />
          <div className="fs_popup__coupon">
            <span>{couponCode}</span>
          </div>
          <div
            className="fs_popup__description"
            dangerouslySetInnerHTML={{ __html: textAfterCoupon }}
          />
        </div>
      </div>
    </aside>
  )
}
export default PopupExit

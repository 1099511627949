import React, { useState } from "react"
import { useForm } from "react-hook-form"
import { formatDate } from "../../../utils/functions"

// import "./style.scss"

const NewsletterForm = ({
  inputPlaceholder,
  successText,
  sendingText,
  errorEmail,
  formId,
  buttonText,
  placeholderBirthday,
  errorBirthday,
  textUnderBirthday,
  consentText,
  errorConsent,
}) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm()
  const [sendingPass, setsendingPass] = useState(false)
  const [loading, setLoading] = useState(false)
  const onSubmit = (values, e) => {
    if (loading) return
    const formData = new FormData()
    let birthdayData = formatDate(values.birthday)

    formData.append("your-email", values.email)
    formData.append("your-birthday", birthdayData)
    setLoading(true)

    return fetch(
      process.env.wordPressUrl +
        "/wp-json/contact-form-7/v1/contact-forms/" +
        formId +
        "/feedback",
      {
        method: "post",
        body: formData,
      }
    ).then(response => {
      if (typeof window !== "undefined") {
        if (typeof window.GrTracking !== "undefined") {
          window.GrTracking('setUserId', values.email); 
          // safe to use the function
        }
      }
      
      if (response.status === 200) {
        setLoading(false)
        setsendingPass(true)
        e.target.reset()
        
        setTimeout(() => {
          setsendingPass(false)
        }, 3500)
      }
    })
  }

  return (
    <form
      className="fs_popup__newsletterForm form"
      onSubmit={handleSubmit(onSubmit)}
    >
      <div className="fs_popup__newsletterForm__wrapper">
        <div className="form__input">
          <input
            placeholder={inputPlaceholder}
            type="email"
            {...register("email", {
              required: true,
              pattern: {
                value:
                  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                message: { errorEmail },
              },
            })}
          />
          <span className="error">{errors.email && errorEmail}</span>
        </div>
        <div className="form__input">
          <input
            placeholder={placeholderBirthday}
            type="text"
            {...register("birthday", {
              pattern: {
                value: /^(0[1-9]|[1-2][0-9]|3[0-1])-(0[1-9]|1[0-2])-\d{4}$/,
                message: { errorBirthday },
              },
            })}
            // text_error_birthday_format
          />
          <span className="error">{errors.birthday && errorBirthday}</span>
          <p className="info">{textUnderBirthday}</p>
        </div>
        <div className="form__checkbox">
          <label className="form__label">
            <input
              type="checkbox"
              {...register("consent", {
                required: true,
                pattern: {
                  value: /^(0[1-9]|[1-2][0-9]|3[0-1])-(0[1-9]|1[0-2])-\d{4}$/,
                  message: { errorConsent },
                },
              })}
              // text_error_birthday_format
            />
            <span className="form__checkbox__info info">{consentText}</span>
          </label>
          <p className="error">{errors.consent && errorConsent}</p>
        </div>

        <div className="form__button">
          <button type="submit">{buttonText}</button>
        </div>
      </div>
      {loading ? <div className="form__load">{sendingText}</div> : ""}
      <div className="form__ok">
        {sendingPass ? <p className="form__thx">{successText}</p> : ""}
      </div>
    </form>
  )
}
export default NewsletterForm
